/*
 * @Author: ai.zhang
 * @Date: 2023-01-11 18:32:05
 * @LastEditors: ai.zhang
 * @Description:
 */
import "./index.scss";

const Home = (): JSX.Element => {
  const downLoadIOS = () => {
    window.open(
      "https://apps.apple.com/us/app/crushe-more-than-a-crush/id1641227122"
    );
  };
  const downLoadGoogle = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel"
    );
  };
  return (
    <>
      <div className="home">
        <div className="header">
          <div className="container">
            <div className="logo">
              <img src={require("../../assets/img/logo.png").default} alt="" />
            </div>
          </div>
        </div>
        <div className="main">
          <div className="container">
            <div className="left">
              <h1
                className="title"
                data-aos="fade-up"
                data-aos-offset="-300"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-anchor-placement="bottom-bottom"
              >
                Next-generation
                <br />
                Soul Metaverse{" "}
              </h1>
              <div
                className="btn_group"
                data-aos="fade-up"
                data-aos-offset="-100"
                data-aos-delay="250"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-anchor-placement="bottom-bottom"
              >
                <button onClick={downLoadIOS}>
                  <img
                    src={require("../../assets/img/ios.png").default}
                    alt=""
                  />
                  <div className="text">
                    <p>Download on the</p>
                    <h6 className="name">App Store</h6>
                  </div>
                </button>
                <button onClick={downLoadGoogle}>
                  <img
                    src={require("../../assets/img/google.png").default}
                    alt=""
                  />
                  <div className="text">
                    <p>Get it on</p>
                    <h6 className="name">Google Play</h6>
                  </div>
                </button>
              </div>
            </div>
            <div className="right">
              <img
                data-aos="fade-left"
                src={require("../../assets/img/banner.png").default}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="left">
              <div className="nav">
                <div className="item">
                  <a href="/privacy" target={"_blank"}>
                    Privacy policy
                  </a>
                </div>
                <span className="shu"></span>
                <div className="item">
                  <a href="/terms" target={"_blank"}>
                    Terms of service
                  </a>
                </div>
                <span className="shu"></span>
                <div className="item">
                  Email:{" "}
                  <a href="mailto:nxgentech2022@gmail.com">
                  hello@nuwa.xyz
                  </a>
                </div>
                <span className="shu"></span>
                <div className="item">
                  Tel: <a href="tel:+6585046391">+65850446391</a>
                </div>
              </div>
            </div>
            <div className="right">© 2023 NXG Labs. All rights reserved.</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
