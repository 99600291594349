/*
 * @Author: ai.zhang
 * @Date: 2023-01-11 18:32:05
 * @LastEditors: ai.zhang
 * @Description: 
 */
import './index.scss'

const Privacy = (): JSX.Element => {
  return (
    <>
        <div className="textContainer">
          <h1 className='title'>Privacy Policy</h1>
          <p className='date'>Effective: 25 October 2022</p>
          <h1 id='welcome-to-crushe'>Welcome to Nuwa!</h1>
          <p><strong>By using the Nuwa website, mobile application (the “App”), and services (collectively, the “Services”), you consent to the following privacy policy.</strong></p>
          <p>We are committed to protecting the privacy of the personal information you provide to us when using Nuwa and we value your privacy. That&#39;s why we have created this document (the “Privacy Policy”). It applies to all Users of the Platform.</p>
          <p>Please note that the Privacy Policy is an integral part of the <a href='/terms'>Terms of Service</a> and that the definitions set forth therein are applicable to this document.</p>
          <p>You should read our entire Privacy Policy, and be aware of the information we collect, how we use it, whom we share it with, and the controls we give you to access, update and delete your information.</p>
          <h3 id='intended-use-and-scope'><strong>Intended Use and Scope</strong></h3>
          <p>This Privacy Policy describes the types of Personal Information that may be gathered about you when you use our Application, how we may use that information and the choices you have about how and if we share it.</p>
          <p>Data on an identifiable individual is called &quot;Personal Data,&quot; and it may come in many forms. Identifiable information includes things like a person&#39;s name, first name, email address, and mailing address, as well as his likeness in a picture or video, his Internet Protocol address, his location, etc.</p>
          <h3 id='1-permission-to-use-our-private-information-and-to-update-it'><strong>1. Permission to Use Our Private Information and to Update It</strong></h3>
          <p>By agreeing to the Terms of Use, you attest that you have read and fully understand this Privacy Policy, which is a fundamental component of the Terms of Service. The User is free to stop using our services if they disagree with one or more of its conditions, especially in case of modification of the terms of the Privacy Policy.</p>
          <p>This privacy policy may change every now and again, but any limitations on your rights won&#39;t be put into effect without your permission. Nuwa will notify Users within the platform if there are updates to the Privacy Policy on the Application. The Privacy Policy may generally be accessed via your User Account settings.</p>
          <h3 id='2-information-you-provide-us'><strong>2. Information You Provide Us</strong></h3>
          <h4 id='profile-data--account'><strong>Profile Data &amp; Account</strong></h4>
          <p>Account creation and User identification. We need to get identity information when users create their accounts. For example, we need to process mandatory personal data such as name, age, gender, and verification code. We also need to process optional profile data such as email addresses, profile pictures, and biography data. Personal information will not be shared with any of our third-party services or partners. If there should be a need to share any of the user&#39;s personal information, it will be with the consent of the user. Our app supports users to delete their accounts. The user shall have the right to discontinue and delete their account and once they delete their account, all their personal information shall be deleted along with the account. We will not share any user information with any third-party services.</p>
          <h4 id='communication-with-crushe'><strong>Communication with Nuwa</strong></h4>
          <p>Your consent or the information we need to react to your request is recorded when you report an issue with the Platform (inappropriate material, bugs, mistakes), complete a survey on the quality of our Services, ask for help, or ask to exercise your rights.</p>
          <h3 id='3-information-we-get-when-you-use-our-service'><strong>3. Information We Get When You Use Our Service</strong></h3>
          <h4 id='a-content-information'><strong>A. Content Information</strong></h4>
          <p>Through our Services, we gather user-generated material including images, videos, and comments and posts in Feed. Keep in mind that any User may easily duplicate your Content by taking a screenshot. Connection Data: IP address, date of registration, date of your last connection. Data about how you use our Services may include the time of posting, number of latest Nuwa posts, etc.</p>
          <p>Therefore, we advise that you not share any content that you do not wish to have others duplicate or see.</p>
          <h4 id='b-geological-information'><strong>B. Geological Information</strong></h4>
          <p>Geological information. When creating an account, users may be asked to add their location and information such as geographical data and IP addresses may be processed. Location information will not be shared with anyone or any of our third-party services and or partners. Should there be reason to share location information, it shall thereby be done with the consent of the user. Tracking shall be an optional feature that the user will have a full decision right to allow or disallow.</p>
          <h4 id='c-device-and-equipment-information'><strong>C. Device and Equipment Information</strong></h4>
          <p>The hardware and software you use to get access Nuwa is logged and analyzed for data. Your IP address, hardware, application failures, OS and language are all part of this data.</p>
          <h4 id='d-cameras-and-photos'><strong>D. Cameras and Photos</strong></h4>
          <p>The camera and pictures. We need to get pictures and other data from your device&#39;s camera and photographs in order to provide many of our services. For instance, until we have access to your camera, photographs, and videos, you won&#39;t be able to edit Nuwa images and or movies or upload from your camera roll.</p>
          <p>We do not track which of our services you have used or how you have used them when you use our services.</p>
          <p>The User&#39;s prior explicit permission is necessary for any use of Personal Data for purposes other than those listed above in our Privacy Policy.</p>
          <h4 id='e-face-detection'><strong>E. Face Detection</strong></h4>
          <p>When using the camera feature, you will need to access our 3D lenses and 2D masks. These lenses are designed to interact with the user&#39;s facial expressions. To achieve better facial recognition and face tracking to mimic the corresponding expressions, we will need to access your facial data. We do not collect information that identifies or allows us to identify you, and face detection algorithms used for processing of your images are not able to identify a specific person and therefore, generally cannot be considered as collecting biometric information. Our app will make use of TrueDepth API to get the necessary facial data for the lenses to be perfectly used when you face swap in photos and videos.</p>
          <p>We use TrueDepth API technologies (ARKit, with automatic estimation of Nuwa-world directional lighting environment) to create AR effects within the App. The only use of this information is to provide valuable user features. None of the information provided via TrueDepth API technologies ever leaves the user&#39;s device. When the effect is rendered when using 3D lens and 2D mask face swap features, the data provided via TrueDepth API is discarded. We do not share information with third parties, store, share or process in any way the data which we accessed via TrueDepth API.</p>
          <p>Nuwa will be responsible for protecting your private data and information. In the event of a data breach in which there has been a collection of your data through our app or an unintentional disclosure or misuse of your data and information, you shall be notified in accordance with the applicable law in your region via an email to notify you.</p>
          <h3 id='4-how-we-store--protect-users-data'>4. How We Store &amp; Protect User&#39;s Data</h3>
          <p>We store our user data on AWS (Amazon Web Services). Data is encrypted while it is stored on AWS&#39;s cloud database; access to the database is restricted to the company&#39;s internal private network, which prevents unauthorized users from accessing sensitive data. Additionally, the API service is configured behind the firewall of the WAF&#39;s protected environment.</p>
          <h3 id='5-information-disclosure-with-third-parties'><strong>5. Information Disclosure with Third Parties</strong></h3>
          <h4 id='no-sharing-of-individual-data-with-third-parties'><strong>No Sharing of Individual Data With Third Parties</strong></h4>
          <p>We do not have any affiliation with any third parties and will not share any of our users&#39; data. To protect the privacy of our Users, Nuwa will not share any information or Personal Data given by them with any other parties, with the exception of Content that you have licensed to us in line with the Terms of Use. You may be certain that Nuwa will not share or sell your Personal Data to any other parties without first obtaining your permission.</p>
          <h4 id='disclosure-required-by-law-or-regulation'><strong>Disclosure Required by Law or Regulation</strong></h4>
          <p>If Nuwa is required by law, regulation, or court order to disclose information or personal data to a third party, or if the disclosure is necessary for the purposes of an investigation, court order, or legal proceeding, either within or outside of the country, Nuwa may disclose such information or data.</p>
          <p>Furthermore, Nuwa may disclose information and Personal Data to unaffiliated third parties, including but not limited to:</p>
          <p>Protect against any violation of the rights, property, or safety of its users, in line with and in accordance with the law; Enforce the then-current Privacy Policy and the General Terms of Use of the Application, including to discover any infraction thereof.</p>
          <h3 id='6-security-of-personal-data'><strong>6. Security of Personal Data</strong></h3>
          <p>You are entitled to the following protections with respect to your Personal Information:</p>
          <p>The right of access grants you, at no cost, access to your own Personal Information.</p>
          <p>The right to rectification lets you change or amend inaccurate information about yourself that we have on file. This option is available under your account settings and may be used at any time.</p>
          <p>When your personal data is no longer needed for the purposes for which they were gathered, or if your consent to their processing has been withdrawn, you have the right to have them deleted.</p>
          <p>You have the limited right to object to our collection and processing of your personal data for commercial prospecting purposes, including profiling where it is connected to commercial prospecting activities. If you disagree with this kind of processing, we&#39;ll need to know why so that we can weigh your concerns against our own when making a decision.</p>
          <p>Data subjects have the right to request that their data be restricted from further use. This is not an unqualified privilege; rather, it may be used only in the following circumstances: a request for correction has been submitted. You have the right to ask that any further use of your personal information be halted while we investigate whether or not the requested correction was made accurately.</p>
          <p>If we have any cause to suspect that you are not who you say you are, we may require you to verify certain information about your Account before acting on your request to exercise one or more of your rights.</p>
          <h3>7. In-app purchase and account information</h3>
          <p>
          When you use the "Note" services on the Nuwa app (including but not limited to purchasing Silver Token/Gold Token/NUWA coins), we will only receive a receipt of the transaction from the operators of the AppStore containing only the transaction information(items you have purchased, the price, etc) to confirm your payment. We will not connect any of your account payment information. You may refer to the privacy policy of the AppStore operators to learn more about how they may process your personal information.
          </p>
          <h3 id='7-information-regarding-children'><strong>8. Information Regarding Teenagers</strong></h3>
          <p>Nuwa is not designed for use by anybody less than 18 years old. You may let us know your concerns by reporting the material or the User Account if you suspect that it was created by or obtained from a teenager under the necessary age.</p>
          <h3 id='8-information-about-users-located-in-the-usa'><strong>9. Information About Users Located In the USA</strong></h3>
          <p>If you are not a party to the Nuwa Terms of Service, this provision does not apply to you and is not legally binding. Personal Information (as defined by relevant laws) given to Nuwa by the User is subject to this provision in the event that the User resides in the United States, including the states of California and Virginia.</p>
          <p>This notice is being sent to you as a service provider obliged to handle your Personal Information (as defined by relevant law) in order to demonstrate that we have suitable contractual terms in place to comply with the California Consumer Privacy Protection Act of 2018 (&quot;CCPA&quot;). For the purposes of managing your Data, &quot;applicable legislation&quot; includes any and all statutes, rules, regulations, standards, regulatory advice, and self-regulatory guidelines that may be relevant. Laws such as the Virginia Consumer Data Protection Act and its counterpart in California&#39;s Civil Code (1798.100-1798.199) are applicable (SB 1392).</p>
          <p>To the fullest extent permitted by law, as a service provider, we will only collect, keep, use, disclose, and process your personal information for the express purpose of carrying out our duties to you under our agreement, for operational reasons as described in this Policy.</p>
          <p>Your information will not be shared with other parties or sold for profit.</p>
          <p>We hereby attest that we are familiar with and in accordance with the provisions of California Civil Code Section 1798.140(w)(2) and Virginia Senate Bill 1392.</p>
          <h3 id='9-contact'><strong>10. Contact</strong></h3>
          <p>You can exercise your rights at any time by contacting Nuwa<a href='mailto:feedback-pukka@mail.io'> </a>at</p>
          <p><a href='mailto:nxgentech2022@gmail.com'>nxgentech2022@gmail.com</a></p>
          <p>phone：+6585046391</p>
      </div>
    </>
  );
}

export default Privacy;